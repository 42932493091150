<template>
  <el-dialog
    id="SelectFileForImportSKUFromXLSDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="520px"
    close-on-press-escape
    append-to-body
    class="my-dialog abow_dialog"
    @opened="onShow"
    destroy-on-close
    @keydown.enter.native="onCommit"
  >
    <el-form
      ref="SelectFileForImportSKUFromXLSForm"
      @submit.native.prevent="onSubmit"
      v-loading="loading"
      :element-loading-text="loadingText"
      :model="model"
      :rules="rules"
    >
      <el-row justify="space-between" type="flex">
        <el-col>
          <el-card shadow="never" border="dashed" class="file">
            <el-upload
              ref="uploadInput"
              :action="uploadAction"
              :headers="uploadHeaders"
              class="upload-demo"
              list-type="text"
              :on-change="onFileSelect"
              :before-remove="onFileSelectRemove"
              :limit="1"
              :file-list="fileList"
              show-file-list
              accept=".xlsx,.xls"
              :auto-upload="false"
              :on-error="handleUploadError"
              :on-success="handleUploadSuccess"
              :before-upload="handleUploadBefore"
            >
              <el-button size="small" type="success">{{ $t("Оберіть файл") }}</el-button>
              <div slot="tip" class="el-upload__tip">{{ $t("Підтримуються файли *.xlsx,*.xls") }}</div>
            </el-upload>
          </el-card>
        </el-col>
      </el-row>
      <div class="group-header">{{ $t("Сторінка, рядок") }}</div>
      <el-card shadow="never" border="dashed">
        <el-row justify="space-between" type="flex" :gutter="0">
          <el-col :span="11">
            <el-form-item prop="pageNumber" :label="$t('Номер сторінки в книзі')">
              <el-input v-model="model.pageNumber" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="rowNumber" :label="$t('Початковий рядок')">
              <el-input v-model="model.rowNumber" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <div class="group-header">{{ $t("Номери стовпчиків") }}</div>
      <el-card shadow="never" border="dashed">
        <el-row justify="space-between" type="flex" :gutter="0">
          <el-col :span="11">
            <el-form-item prop="morionColNumber" label="MorionID">
              <el-input v-model="model.morionColNumber" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="nameColNumber" :label="$t('Назва товару')">
              <el-input v-model="model.nameColNumber" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-between" type="flex" :gutter="0">
          <el-col :span="11">
            <el-form-item prop="cipColNumber" :label="$t('CIP ціна')">
              <el-input v-model="model.cipColNumber" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="ColNumber" :label="$t('Бонус')">
              <el-input v-model="model.bonusColNumber" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <div class="group-header">{{ $t("Виробник") }}</div>
      <el-card shadow="never">
        <el-row justify="space-between" type="flex">
          <el-col :span="9">
            <el-form-item>
              <el-radio v-model="model.makerType" label="number">{{ $t("Номер стовпчика") }}</el-radio>
            </el-form-item>
            <el-form-item>
              <el-radio v-model="model.makerType" label="name">{{ $t("із довідника") }}</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item prop="makerColNumber">
              <el-input v-model="model.makerColNumber" autocomplete="off" :disabled="model.makerType == 'name'"></el-input>
            </el-form-item>
            <el-form-item prop="makerName">
              <el-input
                v-model="model.makerName"
                autocomplete="off"
                suffix-icon="el-icon-more"
                readonly
                @mousedown.native="findOrg($event, 'customer')"
                :placeholder="$t('Не обрано')"
                :disabled="model.makerType == 'number'"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <ImportSKU @import-commit="commitSKUList" />
    <FindOrg @find-commit="onFindCommit" bus-event="forms.orgs.dictionary.sku.find" />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";

export default {
  name: "SelectFileForImportSKUFromXLS",
  props: ["busEvent"],
  components: { ImportSKU: () => import("@/components/forms/ImportSKU"), FindOrg: () => import("@/components/forms/FindOrg") },
  data() {
    var that = this;
    var morionOrNameFieldValidator = (rule, value, callback) => {
      if (this.model.morionColNumber || this.model.nameColNumber) {
        callback();
      } else {
        callback(new Error($tt("Це поле має бути заповненим")));
      }
    };
    var makerColNumberOrNameFieldValidator = (rule, value, callback) => {
      if (this.model.makerColNumber || this.model.makerName) {
        callback();
      } else {
        callback(new Error($tt("Це поле має бути заповненим")));
      }
    };
    return {
      loading: false,
      loadingText: "",
      dialogShow: false,
      title: $tt("Завантаження файлів"),
      operation: null,
      fileList: [],
      model: {},
      initModel: {},
      importRequestTimerBegin: 0,
      importTimer: null,
      maxRequestTime: 60 * 1000 * 5,
      fastTimeout: 10 * 1000,
      rules: {
        pageNumber: [this.fieldValidators.defaultValidateRule, { validator: this.fieldValidators.digitalValueValidateRule }],
        rowNumber: [this.fieldValidators.defaultValidateRule],
        morionColNumber: [{ validator: this.fieldValidators.digitalValueValidateRule }, { validator: morionOrNameFieldValidator }],
        nameColNumber: [{ validator: this.fieldValidators.digitalValueValidateRule }, { validator: morionOrNameFieldValidator }],
        makerColNumber: [{ validator: this.fieldValidators.digitalValueValidateRule }, { validator: makerColNumberOrNameFieldValidator }],
        makerName: [{ validator: makerColNumberOrNameFieldValidator }],
      },
    };
  },

  methods: {
    onCommit() {
      if (this.fileList.length == 1) {
        this.$refs.SelectFileForImportSKUFromXLSForm.validate((valid) => {
          if (valid) {
            this.$store.dispatch("main/updateUserSettings", { importSKUFromXLS: this.model });
            this.loading = true;
            this.loadingText = $tt("Завантаження...");
            this.$refs.uploadInput.submit();
          } else {
            this.$message.error($tt("Перевірте введені дані!"));
          }
          return false;
        });
      } else {
        this.$message.error($tt("Не обрано файл для імпорту!"));
      }
    },
    onShow() {
      this.$refs.SelectFileForImportSKUFromXLSForm.resetFields();
      this.fileList = [];
      var importSettings = this.$store.getters["main/userSettings"]("importSKUFromXLS");
      this.model = _.extend({}, this.initModel, importSettings);
      delete this.model.skuExists;
      this.loading = false;
    },
    onFileSelect(file, fileList) {
      this.fileList = fileList;
      //fileList.filter((file) => file.raw.type == "application/json");
    },
    onFileSelectRemove(file, fileList) {
      this.fileList = [];
      return true;
    },
    handleUploadError(err) {
      this.loading = false;
      this.$message.error("Upload error! " + err);
    },
    handleUploadSuccess(data) {
      if (data && data.success) {
        this.$message.success($tt("Файл завантажено [{id}]!", { id: data.data.tmpFileName }));
        this.loadingText = $tt("Будь ласка, зачекайте...");
        this.runRequestTimer();
        this.$store
          .dispatch("sku/importFromUploadedFileXLS", {
            fileName: data.data.tmpFileName,
            params: _.extend(this.model, { lang: this.$store.getters["main/userSettings"]("userSettings").defaultCatalogLang }),
          })
          .finally(() => {
            this.stopRequestTimer();
            this.loading = false;
            this.$nextTick(() => {
              this.$refs.uploadInput.clearFiles();
              this.fileList = [];
            });
          })
          .then((data) => {
            if (data && data.data && data.data.length) {
              bus.$emit("forms.sku.import.show", { title: $tt("Результат імпорту"), skuExists: this.initModel.skuExists });
            }
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка!"), err);
          });
      } else {
        this.$message.error($tt("Помилка!") + data.msg);
      }
    },
    handleUploadBefore(file) {
      const isLessThan100M = file.size <= 100 * 1024 * 1024; // 100M
      if (!isLessThan100M) {
        this.$message.error($tt("Розмір файлу має бути меньшим за 100 Mб!"));
        this.loading = false;
      }
      return isLessThan100M;
    },
    commitSKUList(data) {
      if (data && data.data && data.data.length > 0) {
        this.dialogShow = false;
        this.$emit("commit", { data: data.data, operation: this.operation });
      }
    },
    onFindCommit(data) {
      this.makerComputed = data.result[0] ? data.result[0].name : null;
    },
    findOrg(event, id) {
      switch (id) {
        case "customer": {
          bus.$emit("forms.orgs.dictionary.sku.find.show", { target: "customer", title: $tt("Пошук організації виробника"), singleRecord: true });
          break;
        }
        default:
          break;
      }
    },
    runRequestTimer() {
      this.importRequestTimerBegin = new Date().getTime();
      this.importTimer = setInterval(() => {
        var currentTime = new Date().getTime();
        if (currentTime - this.importRequestTimerBegin > this.fastTimeout) {
          this.loadingText =
            $tt("Будь ласка, зачекайте...") + "[" + this.milisecondsToMinSec(this.maxRequestTime - (currentTime - this.importRequestTimerBegin)) + "]";
        }
      }, 1000);
    },
    stopRequestTimer() {
      if (this.importTimer) clearInterval(this.importTimer);
    },
  },
  created() {
    bus.$on(this.busEvent + ".show", (data) => {
      this.title = (data && data.title) || this.title;
      this.dialogShow = true;
      this.initModel = _.extend(
        { pageNumber: 1, rowNumber: 1, morionColNumber: 1, nameColNumber: 2, makerColNumber: 3, cipColNumber: 4, makerType: "number", makerName: null },
        data.initModel
      );
      this.operation = data.operation;
    });
    bus.$on(this.busEvent + ".hide", () => {
      this.dialogShow = false;
    });
    bus.$on(this.busEvent + ".clear", () => {
      this.fileList = [];
      this.$refs.uploadInput.clearFiles();
    });
  },
  computed: {
    uploadAction() {
      return axios.defaults.baseURL + "/api/drugs/upload/xls";
    },
    uploadHeaders() {
      return {
        token: this.$store.getters["main/getToken"](),
      };
    },
    makerComputed: {
      get() {
        if (!this.model.makerName) return null;
        else return this.model.makerName;
      },
      set(value) {
        this.model.makerName = value;
      },
    },
  },
};
</script>
<style lang="scss">
#SelectFileForImportSKUFromXLSDialog {
  .el-dialog {
    max-height: 746px !important;
  }
}
#SelectFileForImportSKUFromXLSDialog {
  .el-card {
    border: 1px dashed #ebeef5;
  }
  .el-card__body {
    padding: 16px;
  }
  .group-header {
    font-size: 17px;
    line-height: 24px;
  }
}
</style>
